<template>
    <card-game-stage
        :controllers="controllersInst"
        :display="displayInst"
        :hide-daily-challenge="true"
        :show-hint-button="true"
        :hide-stock-card="true"
    >
        <template v-slot:frames>
            <frames />
        </template>
    </card-game-stage>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { controllers } from '@/games/canfield/controllers/controllers';
import Frames from '@/games/canfield/views/Frames.vue';
import CardGameStage from '@/components/CardGameStage.vue';
import { display } from '@/games/canfield/display';

export default defineComponent({
    components: {
        Frames,
        CardGameStage,
    },

    setup() {
        const controllersInst = computed(() => controllers);
        const displayInst = computed(() => display);

        return {
            controllersInst,
            displayInst,
        };
    },
});
</script>
