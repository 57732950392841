
import { computed, defineComponent } from 'vue';
import { cardImages } from '@/core/card-images';

export default defineComponent({
    props: {
        suit: { type: String, required: true },
        highlight: { type: Boolean },
        leadingNumber: Number,
    },

    setup(props) {
        const image = computed(() => {
            if (props.leadingNumber == 0) {
                return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
            }
            const n = props.leadingNumber || 1;
            const s = props.suit.substr(0, 1).toUpperCase();
            return cardImages[`${n}${s}`];
        });

        return {
            image,
        };
    },
});
