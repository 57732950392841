import { ControllersBase } from '@/core/controllers.base';
import { GameController } from '@/games/canfield/controllers/game.controller';
import { DealerController } from '@/games/canfield/controllers/dealer.controller';
import { DragController } from '@/games/canfield/controllers/drag.controller';
import { MoveController } from '@/games/canfield/controllers/move.controller';
import { DrawController } from '@/games/canfield/controllers/draw.controller';
import { AutoMoveController } from '@/games/canfield/controllers/auto-move.controller';

export class Controllers extends ControllersBase {
    init(): void {
        this.add(new GameController());
        this.add(new DealerController());
        this.add(new DragController());
        this.add(new MoveController());
        this.add(new DrawController());
        this.add(new AutoMoveController());
    }
}

export const controllers = new Controllers();
