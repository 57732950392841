import { CardOwner } from '@/core/models';
import { CardDealerBaseController } from '@/core/card-dealer.base.controller';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreBus } from '@/core/core-bus';
import { coreUtil } from '@/core/core-util';
import { judge } from '@/games/canfield/judge';
import { gameService } from '@/state/game/game.service';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 18);
    }

    protected dealCard(index: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            throw new Error('no card in stock');
        }

        // reserve
        if (index <= 13) {
            cardsService.update(card.id, {
                owner: CardOwner.reserve,
                ownerIndex: 1,
                order: index,
                isFaceUp: index == 13,
                dragEnabled: index == 13,
            });
        }

        // tableau
        if (index >= 14 && index <= 17) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: index - 13,
                order: 1,
                isFaceUp: true,
                dragEnabled: true,
            });
        }

        // foundation
        if (index == 18) {
            cardsService.update(card.id, {
                owner: CardOwner.foundation,
                ownerIndex: coreUtil.getFoundation4IndexForSuit(card.suit),
                order: 1,
                isFaceUp: true,
                dragEnabled: true,
            });
            judge.setLeadCard(card);
            gameService.setFoundationLeadingNumber(card.rank);
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
