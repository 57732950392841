import { Card, CardOwner } from '@/core/models';
import { coreUtil } from '@/core/core-util';
import { cardsQuery } from '@/state/cards/cards.query';

export class Judge {
    private gameLeadCard: Card | null = null;

    setLeadCard(card: Card) {
        this.gameLeadCard = card;
    }

    get leadCard() {
        if (!this.gameLeadCard) {
            throw new Error('missing lead card');
        }
        return this.gameLeadCard;
    }

    canPutCardOnTopOf(card: Card, dest: Card) {
        if (dest.owner != CardOwner.tableau) {
            return false;
        }
        if (card.ownerIndex == dest.ownerIndex && card.owner == dest.owner) {
            return false;
        }
        if (coreUtil.isSameSuitColor(card.suit, dest.suit)) {
            return false;
        }
        if (dest.rank == 1 && card.rank == 13) {
            return true;
        }
        return card.rank == dest.rank - 1;
    }

    canMoveToEmptyTableauFrame(card: Card, ownerIndex: number) {
        if (card.owner != CardOwner.reserve) {
            return false;
        }
        return !cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, ownerIndex);
    }

    canPutInFoundation(card: Card, foundIndex: number) {
        if (card.owner == CardOwner.foundation) {
            return false;
        }
        const suit = coreUtil.getFoundationSuitByIndex(foundIndex);
        if (card.suit != suit) {
            return false;
        }

        // should be at the top in tableau
        const topTab = cardsQuery.getTopByOwnerAndIndex(card.owner, card.ownerIndex);
        if (!topTab || card.id != topTab.id) {
            return false;
        }

        const topFound = cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, foundIndex);
        if (!topFound) {
            return card.rank == this.leadCard.rank;
        }

        if (topFound.rank == 13) {
            return card.rank == 1;
        }

        return topFound.rank + 1 == card.rank;
    }

    canMoveToWaste(card: Card) {
        if (card.owner != CardOwner.stock) {
            return false;
        }
        const top = cardsQuery.getTopByOwner(CardOwner.stock);
        return top && top.id == card.id;
    }
}

export const judge = new Judge();
