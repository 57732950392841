
import { defineComponent, ref } from 'vue';
import FoundationFrame from '@/components/FoundationFrame.vue';
import EmptyFrame from '@/components/EmptyFrame.vue';
import CardStock from '@/components/CardStock.vue';
import { subscribeTo, useObservable } from '@/core/rxjs-helpers';
import { gameQuery } from '@/state/game/game.query';
import { display } from '@/games/canfield/display';
import { recomputable } from '@/composable/recomputable';
import { Position } from '@/core/models';

export default defineComponent({
    components: {
        FoundationFrame,
        EmptyFrame,
        CardStock,
    },

    setup() {
        const foundationHighlight = ref(0);
        const recompute = ref(0);
        const leadingNumber = useObservable(gameQuery.foundationLeadingNumber$);

        subscribeTo(gameQuery.foundationIndexHighlight$, (index) => {
            if (index) {
                foundationHighlight.value = index;
            } else {
                foundationHighlight.value = 0;
            }
        });

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const getFrameStyle = (pos: Position) => {
            return {
                top: `${pos.y}px`,
                left: `${pos.x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const getFoundation = () => {
            return {
                lineHeight: `${display.cardSize.height}px`,
                fontSize: `${display.cardSize.height * 0.3}px`,
            };
        };

        const foundation1Style = recomputable(recompute, () => {
            const frame = getFrameStyle(display.foundationPosition[1]);
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation2Style = recomputable(recompute, () => {
            recompute.value;
            const frame = getFrameStyle(display.foundationPosition[2]);
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation3Style = recomputable(recompute, () => {
            recompute.value;
            const frame = getFrameStyle(display.foundationPosition[3]);
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation4Style = recomputable(recompute, () => {
            recompute.value;
            const frame = getFrameStyle(display.foundationPosition[4]);
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const stockStyle = recomputable(recompute, () => {
            recompute.value;
            return {
                top: `${display.stockPosition.y}px`,
                left: `${display.stockPosition.x}px`,
            };
        });

        const restartIconStyle = recomputable(recompute, () => {
            recompute.value;
            const s = display.cardSize.width * 0.4;
            return {
                height: `${s}px`,
                width: `${s}px`,
            };
        });

        const tableau1Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[1]);
        });

        const tableau2Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[2]);
        });

        const tableau3Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[3]);
        });

        const tableau4Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[4]);
        });

        return {
            foundationHighlight,
            foundation1Style,
            foundation2Style,
            foundation3Style,
            foundation4Style,
            stockStyle,
            restartIconStyle,
            tableau1Style,
            tableau2Style,
            tableau3Style,
            tableau4Style,
            leadingNumber,
        };
    },
});
