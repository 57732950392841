import { Controller } from '@/core/controller';
import { Card, CardOwner, Game, GameStatus } from '@/core/models';
import { judge } from '@/games/canfield/judge';
import { moveHistory } from '@/core/move-history';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { gameService } from '@/state/game/game.service';
import { gameQuery } from '@/state/game/game.query';
import { appQuery } from '@/state/app.query';
import { coreBus } from '@/core/core-bus';
import { cardSound } from '@/core/sound';

export class DrawController extends Controller {
    constructor() {
        super();
        this.subscription.add(
            coreBus.stockClickEvent$.subscribe(() => {
                const card = cardsQuery.getTopByOwner(CardOwner.stock);
                if (card) {
                    this.start(card);
                    coreBus.gameMoveCompletedEvent$.next();
                }
            })
        );
    }

    private start(card: Card) {
        const status = gameQuery.getValue().gameStatus;
        const canMakeMove = status == GameStatus.running || status == GameStatus.dealCompleted;
        if (!judge.canMoveToWaste(card) || !canMakeMove) {
            return;
        }
        moveHistory.startMove();

        // make sure current top waste1 is drag disabled
        const tw1 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
        if (tw1 && tw1.dragEnabled) {
            cardsService.update(tw1.id, {
                dragEnabled: false,
            });
            moveHistory.addState(tw1);
        }

        // set the drawing number based on game
        const game = appQuery.getActiveGame();
        const drawCount = game == Game.canfield1 ? 1 : 3;
        const cardsToMove = cardsQuery.getTopByOwnerLimit(CardOwner.stock, drawCount);

        // move 3 cards
        if (cardsToMove.length == 3) {
            this.move3Cards(cardsToMove);
        }

        // move 2 cards
        if (cardsToMove.length == 2) {
            this.move2Cards(cardsToMove);
        }

        // move 1 cards
        if (cardsToMove.length == 1) {
            this.move1Cards(cardsToMove[0]);
        }

        gameService.increaseMoveCounter();
        moveHistory.endMove();

        // play sound
        cardSound.playMove();
    }

    private move3Cards(cards: Card[]) {
        let order = this.getOrder();

        // first we move waste2 and 3 to waste1
        [
            cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2),
            cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 3),
        ].forEach((c) => {
            if (c) {
                cardsService.update(c.id, {
                    owner: CardOwner.waste,
                    ownerIndex: 1,
                    dragEnabled: false,
                });
                moveHistory.addState(c);
                setTimeout(() => {
                    coreBus.cardMoveCmd$.next({
                        cardId: c.id,
                        duration: 0,
                    });
                }, 300);
            }
        });

        // move the 3 stock cards
        cards.forEach((c, i) => {
            cardsService.update(c.id, {
                owner: CardOwner.waste,
                ownerIndex: i + 1,
                order: order++,
                isFaceUp: true,
                dragEnabled: i == 2,
            });
            moveHistory.addState(c);
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 300,
            });
        });
    }

    private move2Cards(cards: Card[]) {
        let order = this.getOrder();

        // first we move waste2 and 3 to waste1
        [
            cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2),
            cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 3),
        ].forEach((c) => {
            if (c) {
                cardsService.update(c.id, {
                    owner: CardOwner.waste,
                    ownerIndex: 1,
                    dragEnabled: false,
                });
                moveHistory.addState(c);
                coreBus.cardMoveCmd$.next({
                    cardId: c.id,
                    duration: 300,
                });
            }
        });

        // move the 2 stock cards
        const w1 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
        cards.forEach((c, i) => {
            /* eslint-disable */
            const ownerIndex = w1 ? (i == 0 ? 2 : 3) : i == 0 ? 1 : 2;
            cardsService.update(c.id, {
                owner: CardOwner.waste,
                ownerIndex,
                order: order++,
                isFaceUp: true,
                dragEnabled: i == 1,
            });
            moveHistory.addState(c);
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 300,
            });
        });
    }

    private move1Cards(card: Card) {
        let order = this.getOrder();

        // if we have card on waste3
        const w3 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 3);
        const w2 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2);
        if (w3) {
            // move waste3 to 2
            cardsService.update(w3.id, {
                owner: CardOwner.waste,
                ownerIndex: 2,
                dragEnabled: false,
            });
            moveHistory.addState(w3);
            coreBus.cardMoveCmd$.next({
                cardId: w3.id,
                duration: 300,
            });

            // move waste 2 to 1
            if (w2) {
                cardsService.update(w2.id, {
                    owner: CardOwner.waste,
                    ownerIndex: 1,
                    dragEnabled: false,
                });
                moveHistory.addState(w2);
                coreBus.cardMoveCmd$.next({
                    cardId: w2.id,
                    duration: 300,
                });
            }

            // move card to waste3
            cardsService.update(card.id, {
                owner: CardOwner.waste,
                ownerIndex: 3,
                order: order++,
                isFaceUp: true,
                dragEnabled: true,
            });
            moveHistory.addState(card);
            coreBus.cardMoveCmd$.next({
                cardId: card.id,
                duration: 300,
            });

            return;
        }

        // if we have card on waste2
        if (w2) {
            // move waste2 to 1
            cardsService.update(w2.id, {
                dragEnabled: false,
            });
            moveHistory.addState(w2);

            // move card to waste3
            cardsService.update(card.id, {
                owner: CardOwner.waste,
                ownerIndex: 3,
                order: order++,
                isFaceUp: true,
                dragEnabled: true,
            });
            moveHistory.addState(card);
            coreBus.cardMoveCmd$.next({
                cardId: card.id,
                duration: 300,
            });

            return;
        }

        // if we have card on waste1
        const w1 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
        if (w1) {
            // move card to waste2
            cardsService.update(card.id, {
                owner: CardOwner.waste,
                ownerIndex: 2,
                order: order++,
                isFaceUp: true,
                dragEnabled: true,
            });
            moveHistory.addState(card);
            coreBus.cardMoveCmd$.next({
                cardId: card.id,
                duration: 300,
            });

            return;
        }

        // move card to waste1
        cardsService.update(card.id, {
            owner: CardOwner.waste,
            ownerIndex: 1,
            order: order++,
            isFaceUp: true,
            dragEnabled: true,
        });
        moveHistory.addState(card);
        coreBus.cardMoveCmd$.next({
            cardId: card.id,
            duration: 300,
        });
    }

    private getOrder() {
        const w3 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 3);
        if (w3) {
            return w3.order + 1;
        }
        const w2 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2);
        if (w2) {
            return w2.order + 1;
        }
        const topWaste = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
        return topWaste ? topWaste.order + 1 : 1;
    }
}
