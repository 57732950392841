import { BaseGameController } from '@/core/base-game.controller';
import { Card, CardOwner, Suit } from '@/core/models';
import { range } from 'lodash';
import { cardsService } from '@/state/cards/cards.service';
import { moveHistory } from '@/core/move-history';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameService } from '@/state/game/game.service';

export class GameController extends BaseGameController {
    constructor() {
        super();
        this.initStage();
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = suits.flatMap((s) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}`,
                        name: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        owner: CardOwner.stock,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    protected initGame(): void {
        gameService.setFoundationLeadingNumber(0);
        cardsService.resetAllCards(CardOwner.stock);
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        return cardsQuery.getAllByOwner(CardOwner.foundation).length == 52;
    }
}
