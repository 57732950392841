import { CardDisplayBase } from '@/core/card-display.base';
import { Card, CardOwner, Position } from '@/core/models';

export class Display extends CardDisplayBase {
    stockPosition: Position = { x: 0, y: 0 };
    reservePosition: Position = { x: 0, y: 0 };
    tableauPosition: { [id: number]: Position } = {};
    foundationPosition: { [id: number]: Position } = {};
    wastePosition: Position = { x: 0, y: 0 };

    constructor() {
        super(7);
    }

    protected calcDisplay(): void {
        const cardWidth = this.cardSize.width;
        const cardHeight = this.cardSize.height;
        const spaceSize = this.spaceHorizontal;

        const foundY = 0;

        this.foundationPosition[1] = {
            x: 0,
            y: foundY,
        };

        this.foundationPosition[2] = {
            x: cardWidth + spaceSize,
            y: foundY,
        };

        this.foundationPosition[3] = {
            x: cardWidth * 2 + spaceSize * 2,
            y: foundY,
        };

        this.foundationPosition[4] = {
            x: cardWidth * 3 + spaceSize * 3,
            y: foundY,
        };

        this.stockPosition = {
            x: cardWidth * 6 + spaceSize * 6,
            y: foundY,
        };

        const tabMultiplayer = this.gameSize.width > 600 ? 2 : 6;
        const tableauY = cardHeight + spaceSize * tabMultiplayer;

        this.tableauPosition[1] = {
            x: 0,
            y: tableauY,
        };

        this.tableauPosition[2] = {
            x: cardWidth + spaceSize,
            y: tableauY,
        };

        this.tableauPosition[3] = {
            x: cardWidth * 2 + spaceSize * 2,
            y: tableauY,
        };

        this.tableauPosition[4] = {
            x: cardWidth * 3 + spaceSize * 3,
            y: tableauY,
        };

        this.reservePosition = {
            x: cardWidth * 5 + spaceSize * 5,
            y: tableauY,
        };

        this.wastePosition = {
            x: cardWidth * 5 + spaceSize * 5,
            y: this.stockPosition.y,
        };
    }

    calcCardPosition(card: Card): Position {
        if (card.owner == CardOwner.reserve) {
            const space = this.cardSize.height * 0.15;
            return {
                x: this.reservePosition.x,
                y: this.reservePosition.y + (card.order - 1) * space,
            };
        }

        if (card.owner == CardOwner.stock) {
            return {
                ...this.stockPosition,
            };
        }

        if (card.owner == CardOwner.tableau) {
            const tabPos = this.tableauPosition[card.ownerIndex];
            const space = this.getTableauVerticalSpace(tabPos.y, card.ownerIndex);
            return {
                x: tabPos.x,
                y: tabPos.y + (card.order - 1) * space,
            };
        }

        if (card.owner == CardOwner.foundation) {
            return {
                ...this.foundationPosition[card.ownerIndex],
            };
        }

        const drawSpace = this.cardSize.width * 0.45;
        if (card.owner == CardOwner.waste && card.ownerIndex == 1) {
            return {
                x: this.wastePosition.x - 2 * drawSpace,
                y: this.wastePosition.y,
            };
        }
        if (card.owner == CardOwner.waste && card.ownerIndex == 2) {
            return {
                x: this.wastePosition.x - drawSpace,
                y: this.wastePosition.y,
            };
        }
        if (card.owner == CardOwner.waste && card.ownerIndex == 3) {
            return {
                x: this.wastePosition.x,
                y: this.wastePosition.y,
            };
        }

        throw new Error(`invalid owner ${card.owner}, ${card.ownerIndex}`);
    }
}

export const display = new Display();
