import { CardMoveBaseController } from '@/core/card-move-base.controller';
import { judge } from '@/games/canfield/judge';
import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { moveHistory } from '@/core/move-history';
import { coreBus } from '@/core/core-bus';
import { gameService } from '@/state/game/game.service';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: true,
            meldCard: {
                validate: (cmd) => judge.canPutCardOnTopOf(cmd.card, cmd.destCard),
                after: (cmd) => {
                    this.moveWasteCardToEmptyTableau();
                    this.reorganizeWasteAfterMoveCardFromWaste(cmd.card);
                },
            },
            moveCardToEmptyTableauCmd: {
                validate: (cmd) => judge.canMoveToEmptyTableauFrame(cmd.card, cmd.tableauIndex),
                after: (cmd) => this.reorganizeWasteAfterMoveCardFromWaste(cmd.card),
            },
            moveCardToFoundation: {
                validate: (cmd) => judge.canPutInFoundation(cmd.card, cmd.foundationIndex),
                after: (cmd) => {
                    this.moveWasteCardToEmptyTableau();
                    this.reorganizeWasteAfterMoveCardFromWaste(cmd.card);
                },
            },
        });
        this.subscription.add(
            coreBus.recycleWasteCmd$.subscribe(() => {
                this.recycleWaste();
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
    }

    private moveWasteCardToEmptyTableau() {
        // check if we have empty tableau
        const empties = [1, 2, 3, 4]
            .map((i) => ({
                c: cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, i),
                i,
            }))
            .filter((d) => !d.c)
            .map((d) => d.i);

        if (empties.length == 0) {
            return;
        }

        const top = cardsQuery.getTopByOwner(CardOwner.reserve);
        if (!top) {
            return;
        }

        cardsService.update(top.id, {
            owner: CardOwner.tableau,
            ownerIndex: empties[0],
            dragEnabled: true,
            order: 1,
        });
        moveHistory.addState(top);

        // if top card is face down we want to flip it
        const top1 = cardsQuery.getTopByOwner(CardOwner.reserve);
        if (top1 && !top1.isFaceUp) {
            cardsService.update(top1.id, {
                isFaceUp: true,
                dragEnabled: true,
            });
            moveHistory.addState(top1);
        }

        coreBus.cardMoveCmd$.next({
            cardId: top.id,
            duration: 300,
        });
    }

    private reorganizeWasteAfterMoveCardFromWaste(cardMovedFromWaste: Card) {
        if (cardMovedFromWaste.owner == CardOwner.waste && cardMovedFromWaste.ownerIndex == 1) {
            return;
        }

        if (cardMovedFromWaste.owner == CardOwner.waste && cardMovedFromWaste.ownerIndex == 2) {
            cardsQuery
                .getAll()
                .filter((c) => c.owner == CardOwner.waste && c.ownerIndex == 1)
                .forEach((c) => {
                    cardsService.update(c.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(c);
                });
            return;
        }

        if (cardMovedFromWaste.owner == CardOwner.waste && cardMovedFromWaste.ownerIndex == 3) {
            const cardsInWaste1 = cardsQuery
                .getAll()
                .filter((c) => c.owner == CardOwner.waste && c.ownerIndex == 1);
            if (cardsInWaste1.length > 1) {
                const cw2 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2);
                if (cw2) {
                    cardsService.update(cw2.id, {
                        owner: CardOwner.waste,
                        ownerIndex: 3,
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw2);
                    coreBus.cardMoveCmd$.next({
                        cardId: cw2.id,
                        duration: 300,
                    });
                }

                const cw1 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
                if (cw1) {
                    cardsService.update(cw1.id, {
                        owner: CardOwner.waste,
                        ownerIndex: 2,
                    });
                    moveHistory.addState(cw1);
                    coreBus.cardMoveCmd$.next({
                        cardId: cw1.id,
                        duration: 300,
                    });
                }
            } else {
                const cw3 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 3);
                if (cw3) {
                    cardsService.update(cw3.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw3);
                    return;
                }

                const cw2 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2);
                if (cw2) {
                    cardsService.update(cw2.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw2);
                    return;
                }

                const cw1 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
                if (cw1) {
                    cardsService.update(cw1.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw1);
                    return;
                }
            }
        }
    }

    private recycleWaste() {
        if (!this.canMakeMove()) {
            return;
        }

        const cards = cardsQuery.getAllByOwner(CardOwner.waste);
        if (cards.length == 0) {
            return;
        }

        moveHistory.startMove();

        const updates = cards.map((c) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.stock,
                isFaceUp: false,
                order: cards.length - c.order + 1,
                dragEnabled: false,
                isShadow: false,
                clickEnabled: cards.length == cards.length - c.order + 1,
            };
        });
        cardsService.upsertMany(updates);
        cardsQuery.getAllByOwner(CardOwner.stock).forEach((c) => {
            coreBus.cardMoveCmd$.next({
                duration: 300,
                cardId: c.id,
            });
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();
    }
}
