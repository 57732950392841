import { CardDragBaseController } from '@/core/card-drag.base.controller';
import { display } from '@/games/canfield/display';
import { judge } from '@/games/canfield/judge';

export class DragController extends CardDragBaseController {
    constructor() {
        super({
            tableauCardIntersect: {
                canPutCardOnTopOf: (card, dest) => judge.canPutCardOnTopOf(card, dest),
            },
            tableauIntersect: {
                frames: () => [
                    { position: display.tableauPosition[1], index: 1 },
                    { position: display.tableauPosition[2], index: 2 },
                    { position: display.tableauPosition[3], index: 3 },
                    { position: display.tableauPosition[4], index: 4 },
                ],
                validate: (card, index) => judge.canMoveToEmptyTableauFrame(card, index),
            },
            foundationIntersect: {
                frames: () => [
                    { position: display.foundationPosition[1], index: 1 },
                    { position: display.foundationPosition[2], index: 2 },
                    { position: display.foundationPosition[3], index: 3 },
                    { position: display.foundationPosition[4], index: 4 },
                ],
                validate: (card, index) => judge.canPutInFoundation(card, index),
            },
        });
    }
}
