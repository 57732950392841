import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';
import { Card, CardOwner, Hint } from '@/core/models';
import { judge } from '@/games/canfield/judge';
import { coreUtil } from '@/core/core-util';
import { coreBus } from '@/core/core-bus';
import { cardsQuery } from '@/state/cards/cards.query';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card): void {
        const valid = [CardOwner.waste, CardOwner.tableau, CardOwner.reserve];
        if (valid.indexOf(card.owner) < 0) {
            return;
        }

        // safe guard check if card is face up
        if (!card.isFaceUp) {
            return;
        }

        // try to move card to foundation
        if (judge.canPutInFoundation(card, coreUtil.getFoundation4IndexForSuit(card.suit))) {
            coreBus.moveCardToFoundationCmd$.next({
                card,
                foundationIndex: coreUtil.getFoundation4IndexForSuit(card.suit),
            });
            return;
        }

        // try to meld card
        const tableauTops = cardsQuery.getTopTableauCards();
        for (let i = 0; i < tableauTops.length; i++) {
            const c = tableauTops[i];
            if (judge.canPutCardOnTopOf(card, c)) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: c,
                });
                return;
            }
        }

        // if we got here we could find any auto move
        // we want to shake the card
        coreBus.shakeCardCmd$.next({
            card,
        });
    }

    protected isAutoFinishPossible(): boolean {
        return false;
    }

    protected doAutoFinish(done: () => void): void {
        done();
    }

    protected generateHints(): Hint[] {
        const tableauTops = cardsQuery.getTopTableauCards();
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau).filter((c) => c.isFaceUp);
        const foundTops = cardsQuery.getTopCardsByOwner(CardOwner.foundation);
        const wasteTop = cardsQuery
            .getTopCardsByOwner(CardOwner.waste)
            .filter((c) => c.dragEnabled);

        // hints for tableau
        const tabHints = tabCards
            .concat(foundTops)
            .concat(wasteTop)
            .flatMap((c) => {
                return tableauTops
                    .filter((tc) => tc.ownerIndex != c.ownerIndex && judge.canPutCardOnTopOf(c, tc))
                    .map(
                        (tc) =>
                            ({
                                card1Id: c.id,
                                card2Id: tc.id,
                                priority: 0,
                            } as Hint)
                    );
            });

        // hints for foundation
        const foundHints = tableauTops
            .concat(wasteTop)
            .filter((c) => judge.canPutInFoundation(c, coreUtil.getFoundation4IndexForSuit(c.suit)))
            .map((c) => {
                const foundIndex = coreUtil.getFoundation4IndexForSuit(c.suit);
                const foundCard = cardsQuery.getTopByOwnerAndIndex(
                    CardOwner.foundation,
                    foundIndex
                );
                return {
                    card1Id: c.id,
                    card2Id: foundCard ? foundCard.id : null,
                    foundationIndex: foundCard ? null : foundIndex,
                } as Hint;
            });

        return tabHints.concat(foundHints);
    }
}
