<template>
    <div class="frame noselect" :class="{ highlight }">
        <img :src="image" class="suit" alt="f" />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { cardImages } from '@/core/card-images';

export default defineComponent({
    props: {
        suit: { type: String, required: true },
        highlight: { type: Boolean },
        leadingNumber: Number,
    },

    setup(props) {
        const image = computed(() => {
            if (props.leadingNumber == 0) {
                return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
            }
            const n = props.leadingNumber || 1;
            const s = props.suit.substr(0, 1).toUpperCase();
            return cardImages[`${n}${s}`];
        });

        return {
            image,
        };
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    padding: 1%;
}
.frame.highlight {
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
}
.frame .suit {
    background-position: center;
    -webkit-background-size: 40% 40%;
    -moz-background-size: 40%;
    -o-background-size: 40%;
    background-size: 40%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.3;
}
@media only screen and (max-width: 600px) {
    .frame.highlight {
        -webkit-box-shadow: inset 0px 0px 5px 2px rgba(255, 255, 255, 1);
        -moz-box-shadow: inset 0px 0px 5px 2px rgba(255, 255, 255, 1);
        box-shadow: inset 0px 0px 5px 2px rgba(255, 255, 255, 1);
    }
}
</style>
